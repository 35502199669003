<script setup lang="ts">
import { decode } from 'blurhash'
import { onMounted, ref } from 'vue'

const { src, srcset, blurHash = 'LEHV6nWB2yk8pyo0adR*.7kCMdnj' } = defineProps<{
  src: string
  srcset?: string
  blurHash?: string
}>()

const img = ref<HTMLImageElement>()
const imageBlurred = ref<string>(getBlurHashCanvas(blurHash))

onMounted(() => {
  img.value!.onload = () => updateImage()
  setTimeout(() => updateImage(), 3_000)
})

function getBlurHashCanvas(blurH: string, w = 30, h = 23): string {
  try {
    if (!document)
      return blurHash
    const canvas: HTMLCanvasElement = document.createElement('canvas') // TODO: document?!
    const pixels = decode(blurH, w, h)
    const ctx = canvas.getContext('2d')!
    canvas.width = w
    canvas.height = h
    const imgData = ctx.createImageData(w, h)
    imgData.data.set(pixels)
    ctx.putImageData(imgData, 0, 0)
    return canvas.toDataURL()
  }
  catch (e) {
    console.error('Error getting BlurHash Canvas: ', e)
    return blurHash
  }
}

function updateImage() {
  if (img.value && img.value!.src !== src) {
    img.value!.src = src!
    imageBlurred.value = src!
  }
}
</script>

<template>
  <img ref="img" class="w-100" :src="imageBlurred" :srcset="srcset">
</template>

<style scoped lang="scss">
.w-100 {
  width: 100%;
}
</style>
