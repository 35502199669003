<script setup lang="ts">
import { useDisplay } from 'vuetify'
import MainScrollContainer from '~/components/main/center/MainScrollContainer.vue'

// definePageMeta({ auth: 'guest' })

useHead({ title: 'ᦘ contemporaneously' })

const { xs } = useDisplay()

// https://github.com/nuxt-modules/device
</script>

<template>
  <section :class="[$device.isDesktopOrTablet ? 'pl-6' : '']">
    <MainScrollContainer />

    <!-- <MainCustomizer v-if="$device.isDesktopOrTablet" /> -->
    <MainCustomizer v-if="!xs" />
  </section>
</template>
