<script setup lang="ts">
import { ref } from 'vue'
import { useDisplay } from 'vuetify'
import type { CardMini } from '~/api/card'
import { CardFinderControllerApi } from '~/api/card/controllers/card-finder-controller-api'
import CardPreview from '~/components/card/CardPreview.vue'
import CardWithPhoto from '~/components/card/CardWithPhoto.vue'
import MapStudioMeta from '~/components/card/MapStudioMeta.vue'
import AlertFirstNoAddress from '~/components/core/alert/AlertFirstNoAddress.vue'
import MembersLine from '~/components/core/members/MembersLine.vue'
import MembersLineStraight from '~/components/core/members/MembersLineStraight.vue'
import { withAxiosToken } from '~/composables/utils'
import { useMemberStore } from '~/stores/members'

const { smAndDown } = useDisplay()
const memberStore = useMemberStore()

const cards = ref<CardMini[]>([])

const limitCard = 2

onBeforeMount(async () => {
  const apiCard = new CardFinderControllerApi(await withAxiosToken())
  cards.value = (await apiCard.findAllActiveOrGuestGroups(limitCard)).data
})

const { data: members } = await useAsyncData('users', async () => await memberStore.getNewMembers())
</script>

<template>
  <section>
    <AlertFirstNoAddress />

    <div v-if="members">
      <MembersLineStraight v-if="smAndDown" :members="members" />
      <MembersLine v-else :members="members" />
    </div>

    <MapStudioMeta />

    <v-row v-if="cards.length > 0" class="mt-4">
      <v-col v-for="card in cards" :key="card.id" cols="12" md="6" lg="6">
        <CardPreview :card="card" />
      </v-col>
    </v-row>

    <CardWithPhoto />
  </section>
</template>
