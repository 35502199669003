<script setup lang="ts">
import FriendRequest from '~/components/card/FriendRequest.vue'
import MessageLastCard from '~/components/card/MessageLastCard.vue'
import ProfileCard from '~/components/card/ProfileCard.vue'
import { useSidebarStore } from '~/stores/sidebar'

const sidebar = useSidebarStore()
</script>

<template>
  <v-navigation-drawer
    v-model="sidebar.sidebar_right"
    :permanent="sidebar.sidebar_right"
    location="right"
    width="400"
    floating
    class="hide-scroll"
  >
    <h3 class="ma-4">
      🔥Hot topics
    </h3>

    <v-card class="px-6 ga-6 d-flex flex-column ">
      <MessageLastCard />

      <FriendRequest />

      <ProfileCard />
    </v-card>
  </v-navigation-drawer>
</template>

<style scoped lang="scss">
.hide-scroll {
  overflow-y: hidden !important;
  -ms-overflow-style: none !important; /* for Internet Explorer, Edge */
  scrollbar-width: none !important; /* for Firefox */
}

.hide-scroll::-webkit-scrollbar {
  display: none !important; /* for Chrome, Safari, and Opera */
}
</style>
