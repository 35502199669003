<script setup lang="ts">
import type { MemberDto } from '~/api/card/models/member-dto'

const { members, size = 45 } = defineProps<{ members: MemberDto[], size?: number }>()

function showBadge(index: number) { // TODO: online
  return index % 2 === 0
}

function xOffset(): number {
  return size / 8
}

function yOffset(): number {
  return size - (size / 4)
}
</script>

<template>
  <v-badge
    v-for="(member, index) in members" :key="index"
    :model-value="showBadge(index)" color="secondary" dot :offset-x="xOffset()" :offset-y="yOffset()"
  >
    <v-avatar :size="size" class="ml-n2 avatar-border">
      <v-img :src="member.avatar" cover alt="user icon" :height="size" />
    </v-avatar>
  </v-badge>
</template>

<style scoped lang="scss">
.avatar-border {
  border: 2px solid rgb(var(--v-theme-surface)) !important;
}
</style>
