<template>
  <section class="glow">
    <slot />
  </section>
</template>

<style scoped lang="scss">
.glow {
  position: relative;

  :first-child {
    z-index: 1;
  }

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    content: '';
    border-radius: 5px;
    box-shadow: 0 0 17px 3px rgb(var(--v-theme-info)), 0 0 4px 2px rgb(var(--v-theme-info));
    animation-name: half-yellow-shadow;
    animation-duration: 9s;
    animation-timing-function: ease;
    animation-iteration-count: infinite;
  }

  &::after {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 50%;
    height: 100%;
    content: '';
    border-radius: 5px;
    box-shadow: 0 0 17px 3px rgb(var(--v-theme-secondary)), 0 0 4px 2px rgb(var(--v-theme-secondary));
    animation-name: half-cyan-shadow;
    animation-duration: 9s;
    animation-timing-function: ease;
    animation-iteration-count: infinite;
  }
}

@keyframes half-yellow-shadow {
  0% {
    top: 0;
    left: 0;
    width: 50%;
    height: 50%;
  }

  16.66% {
    top: 0;
    left: 0;
    width: 100%;
    height: 50%;
  }

  32.32% {
    top: 0;
    left: 50%;
    width: 50%;
    height: 50%;
  }

  49.98% {
    top: 50%;
    left: 50%;
    width: 50%;
    height: 50%;
  }

  66.64% {
    top: 50%;
    left: 0;
    width: 100%;
    height: 50%;
  }

  83.3% {
    top: 50%;
    left: 0;
    width: 50%;
    height: 50%;
  }

  100% {
    top: 0;
    left: 0;
    width: 50%;
    height: 50%;
  }
}

@keyframes half-yellow-shadow {
  0% {
    top: 0;
    left: 0;
    width: 50%;
    height: 50%;
  }

  16.66% {
    top: 0;
    left: 0;
    width: 100%;
    height: 50%;
  }

  32.32% {
    top: 0;
    left: 50%;
    width: 50%;
    height: 50%;
  }

  49.98% {
    top: 50%;
    left: 50%;
    width: 50%;
    height: 50%;
  }

  66.64% {
    top: 50%;
    left: 0;
    width: 100%;
    height: 50%;
  }

  83.3% {
    top: 50%;
    left: 0;
    width: 50%;
    height: 50%;
  }

  100% {
    top: 0;
    left: 0;
    width: 50%;
    height: 50%;
  }
}

@keyframes half-cyan-shadow {
  0% {
    right: 0;
    bottom: 0;
    width: 50%;
    height: 50%;
  }

  16.66% {
    right: 0;
    bottom: 0;
    width: 100%;
    height: 50%;
  }

  32.32% {
    right: 50%;
    bottom: 0;
    width: 50%;
    height: 50%;
  }

  49.98% {
    right: 50%;
    bottom: 50%;
    width: 50%;
    height: 50%;
  }

  66.64% {
    right: 0;
    bottom: 50%;
    width: 100%;
    height: 50%;
  }

  83.3% {
    right: 0;
    bottom: 50%;
    width: 50%;
    height: 50%;
  }

  100% {
    right: 0;
    bottom: 0;
    width: 50%;
    height: 50%;
  }
}

@keyframes half-cyan-shadow {
  0% {
    right: 0;
    bottom: 0;
    width: 50%;
    height: 50%;
  }

  16.66% {
    right: 0;
    bottom: 0;
    width: 100%;
    height: 50%;
  }

  32.32% {
    right: 50%;
    bottom: 0;
    width: 50%;
    height: 50%;
  }

  49.98% {
    right: 50%;
    bottom: 50%;
    width: 50%;
    height: 50%;
  }

  66.64% {
    right: 0;
    bottom: 50%;
    width: 100%;
    height: 50%;
  }

  83.3% {
    right: 0;
    bottom: 50%;
    width: 50%;
    height: 50%;
  }

  100% {
    right: 0;
    bottom: 0;
    width: 50%;
    height: 50%;
  }
}
</style>
