<script setup lang="ts">
import { ActivityHeartbeatIcon } from 'vue-tabler-icons'
import type { MemberDto } from '~/api/card/models/member-dto'
import MembersRowImg from '~/components/core/members/MembersRowImg.vue'

const { members } = defineProps<{ members: MemberDto[] }>()
const buttonSize = 48
</script>

<template>
  <section class="pl-2 d-flex align-center justify-space-between">
    <div class="d-flex flex-row-reverse">
      <MembersRowImg :members="members" />
    </div>

    <v-btn icon variant="text" :size="buttonSize" color="secondary">
      <v-icon :size="buttonSize">
        <ActivityHeartbeatIcon :size="buttonSize" />
      </v-icon>
    </v-btn>
  </section>
</template>

<style scoped lang="scss">
.avtar-border {
  border: 2px solid rgb(var(--v-theme-surface)) !important;
}
</style>
