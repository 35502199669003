<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { ref } from 'vue'
import { HomeCancelIcon, HomeCheckIcon, XIcon } from 'vue-tabler-icons'
import { useAddressStore } from '~/stores/address'
import { useUser } from '~/composables/auth/use-customer-info'

const addressStore = useAddressStore()
const { myAddress } = storeToRefs(addressStore)

const showAlert = ref<boolean>(false)

onBeforeMount(async () => {
  if (useUser().value) {
    await addressStore.fetchMyAddress()
    showAlert.value = !myAddress.value!.confirmed!
  }
  else {
    showAlert.value = false
  }
})

const title = 'Welcome to Colaba space! 👋🏻'
const text1 = `To help you find friends nearby, we need to confirm your location. Please `
const text2 = `verify or update your address.`
const text3 = ` This will only take a minute and help you get the most out of our community.`
const textBtn = `Update Location`
</script>

<template>
  <v-alert
    v-model="showAlert"
    closable prominent
    class="mb-6"
    border="start"
    variant="outlined"
    close-label="Close Alert"
    :close-icon="XIcon"
    color="primary"
    :title="title"
    :icon="HomeCancelIcon"
    rounded="lg"
  >
    <v-row class="align-center">
      <v-col xs="12" sm="5" md="6" lg="8" xl="6" class="text-subtitle-1 font-weight-thin mt-3">
        {{ text1 }} <nuxt-link to="/personal/address">
          {{ text2 }}
        </nuxt-link>{{ text3 }}
      </v-col>

      <v-col class="d-flex justify-center mb-3">
        <!--        <v-avatar size="120" class="mb-n5"><v-img :src="starImg" alt="breadcrumb" /></v-avatar> -->
        <v-btn to="/personal/address" variant="tonal" color="primary" :append-icon="HomeCheckIcon">
          {{ textBtn }}
        </v-btn>
      </v-col>
    </v-row>
  </v-alert>
</template>
