<script setup lang="ts">
import { ArrowsDiagonalMinimize2Icon } from 'vue-tabler-icons'
import { useUser } from '~/composables/auth/use-customer-info'

const staticP = `${useRuntimeConfig().public.STATIC}/img/profile`

const avatar = `${staticP}/user-1.jpg`

const user = useUser()

function acceptInvite() {
  if (!user.value)
    navigateTo('/login?t=1')
}
function declineInvite() {
  if (!user.value)
    navigateTo('/login?t=1')
}

const friendsCount = 2
</script>

<template>
  <v-card elevation="6" class="border-thin text-center pt-4 pb-6">
    <v-card-title class="font-weight-thin">
      <span class="text-secondary">  {{ friendsCount }} friends </span> suggestions
    </v-card-title>

    <v-badge class="mb-3" :content="friendsCount" color="accent" offset-x="18" offset-y="18">
      <v-avatar size="120">
        <v-img :src="avatar" alt="Avatar of user who requested to be a friend" />
      </v-avatar>
    </v-badge>

    <h5 class="text-h5">
      Tommoie Henderson
    </h5>
    <h6 class="line-dense text-subtitle-1 text-13 mt-2 text-disabled" data-test="friend-rq-instruction-test">
      Accept the request and<br> type a message
    </h6>

    <section class="mt-5 mx-auto">
      <v-btn variant="tonal" color="primary" :append-icon="ArrowsDiagonalMinimize2Icon" @click="acceptInvite()">
        Accept
      </v-btn>
      <v-btn variant="plain" class="ml-4" @click="declineInvite()">
        Decline
      </v-btn>
    </section>
  </v-card>
</template>

<style scoped lang="scss">
.z-ind {
  z-index: 3;
}
</style>
