<script setup lang="ts">
import { format, sub } from 'date-fns'
import { CircleIcon, EyeIcon, Message2Icon } from 'vue-tabler-icons'
import CounterChip from '~/components/core/icons/CounterChip.vue'

interface MiniMember {
  id: number
  avatar: string
  name: string
}

export interface Comment {
  id?: string | number
  profile?: MiniMember
  time?: Date
  comment?: string
  replies?: Comment[]
}

interface CardWithPhoto {
  id?: string | number
  title?: string
  content?: string
  coverImg?: string
  createdAt?: string | Date
  viewsCount?: number
  sharesCount?: number
  category?: string
  author?: MiniMember
  comments?: Comment[]
}

const staticP = `${useRuntimeConfig().public.STATIC}/img`

const post: CardWithPhoto = {
  id: 1,
  title: 'Garmins Instinct Crossover is a rugged hybrid smartwatch',
  content: 'It includes the basic building blocks for all these items and is built on top of a Mersenne Twister so it can generate these things with repeatability, if desired.',
  coverImg: `${staticP}/blog/blog-img5.jpg`,
  createdAt: sub(new Date(), { days: 8, hours: 6, minutes: 20 }),
  viewsCount: 122,
  sharesCount: 32,
  category: 'Gadget',
  author: {
    id: -1,
    avatar: `${staticP}/profile/user-3.jpg`,
    name: 'Old Guy Jesus',
  },
}
</script>

<template>
  <v-card elevation="10" class="text-white mt-6 overflow-hidden card-hover">
    <v-img height="350" :src="post.coverImg" cover gradient="to bottom, rgba(0,0,0,.5), rgba(0,0,0,.5)">
      <v-container class="fill-height px-6">
        <header class="d-flex justify-space-between align-center w-100 align-self-start">
          <v-avatar size="40">
            <v-img :src="post?.author?.avatar" height="40" />
          </v-avatar>
          <v-chip class="text-body-2 font-weight-thin" size="small">
            {{ post?.category }}
          </v-chip>
        </header>

        <article class="align-self-end">
          <h3 class="text-h4 text-20 my-6 ">
            <!-- <NuxtLink class="text-decoration-none color-inherits" :to="linkTo"> -->
            {{ post?.title }}
            <!-- </NuxtLink> -->
          </h3>

          <footer class="d-flex justify-space-between">
            <section class="text-subtitle-1 d-flex">
              <CounterChip :icon="EyeIcon" :value="post.viewsCount" />
              <CounterChip :icon="Message2Icon" :value="123" class="ml-4" />
            </section>
            <section>
              <v-icon size="10" color="info">
                <CircleIcon />
              </v-icon>
              <span class="text-subtitle-2 ml-2" v-text="format(new Date(post.createdAt!!), 'E, MMM d')" />
            </section>
          </footer>
        </article>
      </v-container>
    </v-img>
  </v-card>
</template>

<style scoped lang="scss">
.dummy {
  height: 1px;
}
</style>
